import React from "react"
import { graphql, PageProps } from "gatsby"
import { SEO } from "../components/seo/seo"
import { AlertBanner } from "../components/alert-banner/AlertBanner"
import { HeroBanner } from "../components/hero/HeroBanner"
import { GlobalFooter } from "../components/global-footer/GlobalFooter"
import { useLogos } from "../hooks/queries/settings/useLogos"
import { ContentBlocks } from "../components/content-blocks/ContentBlocks"
import { Layout } from "../components/layout"
import { StubBanner } from "../components/form-stub/StubBanner"
import { useGeneralSettings } from "../hooks/useGeneralSettings"
import { TabbedHeroBanner } from "../components/hero/TabbedHeroBanner"


interface GenericInteriorProps extends PageProps {
    pageContext: {
      slug: string
      breadcrumb: {
        crumbs: Record<string, string>[]
        location: string
      }
      linkedPagesUrlPathCodex: Record<string, string>
    }
    data: any
  }

const ghostHome: React.FC<GenericInteriorProps> = ({ location, data, pageContext }) => {
  const { pathname, hash } = location
  const { linkedPagesUrlPathCodex } = pageContext

  const { isAlertEnabled, alertBanner, globalFormStubBanner } =
    useGeneralSettings()

  const { headerLogo, faviconLogo } = useLogos()

//  const { allKontentItemPage } = useStaticQuery(getHomePage)
 console.log("PAGE DATA",data)
  const [home] = data.allKontentItemPage.nodes

  const {
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    seo_metadata__rich_data_image,
    alternating_content,
    banner,
    rich_content,
    global_footer,
  } = home.elements

  const blockAfterFormStubHasHeading = rich_content?.modular_content[0]
    ?.elements?.heading?.value
    ? true
    : false
  const hasAlternatingContentBlocks =
    alternating_content.value[0]?.codename === "no" ? false : true

  return (
    <Layout
      location="/"
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={seo_metadata__meta_title?.value}
        description={seo_metadata__meta_description?.value}
        canonical={seo_metadata__canonical_link?.value}
        image={seo_metadata__rich_data_image}
        fallbackImage={headerLogo}
        favicon={faviconLogo}
        noIndex={true}
      />
      {isAlertEnabled && alertBanner && (
        <AlertBanner
          title={alertBanner.alertText}
          url={alertBanner.linkUrl}
          iconCodeName={alertBanner.iconCodeName}
        />
      )}

      {banner.value[0].elements.hasOwnProperty("hero_tabs") ? (
        <TabbedHeroBanner banner={banner.value[0].elements} />
      ) : (
        <HeroBanner banner={banner.value[0].elements} />
      )}

      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner
          formStub={globalFormStubBanner}
          nextBlockHasHeading={blockAfterFormStubHasHeading}
        />
      )}

      {rich_content?.modular_content?.length > 0 && (
        <ContentBlocks
          content={rich_content.modular_content}
          hasAlternatingContentBlocks={hasAlternatingContentBlocks}
        />
      )}

      {global_footer && global_footer?.value.length > 0 && (
        <GlobalFooter content={global_footer} />
      )}
    </Layout>
  )
}
export const getHomePage = graphql`
  query GhostHome {
    allPages: allSitePage(
      filter: {path: {regex: "/^(?!.*\\/page-).*$/", nin: ["/404/", "/404.html", "/", "/login", "/ghosthome"]}}
    ) {
      totalCount
      nodes {
        path
      }
    }
    loginItems: allKontentItemPage (
      filter: { elements: { slug: { value: { eq: "login" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...LoginBannerFragment
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
    page404Items: allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "404" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...HeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
    allKontentItemPage(filter: { elements: { slug: { value: { eq: "/" } } } }) {
      nodes {
        elements {
          alternating_content {
            value {
              codename
            }
          }
          legacy_navigation_grid_alt_background {
            value {
              codename
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__overlay_cta_text {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          page_type{
            value {
              codename
            }
          }
          is_location_page {
            value {
              codename
            }
          }
          noindex_this_page {
            value {
              codename
            }
          }
          custom_tracking_number{
            value
          }
          is_gallery_parent_page {
            value {
              codename
            }
          }
          is_pest_library_parent_page {
            value {
              codename
            }
          }
          banner {
            value {
              ...HeroBannerFragment
              ...TabbedHeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
          slug_generator {
            value
          }
          slug{
            value
          }
        }
      }
    }
    kontentItemSettings {
      elements {

        company_name {
          value
        }
        company_email{
          value
        }
        site_name {
          value
        }
        custom_cta_text {
          value
        }
        enable_alert_banner {
          value {
            codename
          }
        }
        custom_tracking_number_cta_button_text {
          value
        }
        custom_tracking_number_modal_heading {
          value
        }
        use_phone_number_not_tracking_number {
          value {
            codename
          }
        }
        alert_banner {
          ...AlertBannerFragment
        }
        social_media {
          modular_content {
            ...SocialMediaLinkFragment
          }
        }
        custom_css {
          value {
            url
          }
        }
        custom_css_code {
          value
        }
        scripts {
          modular_content {
            ... on kontent_item_scripts__copy_ {
              elements {
                script {
                  value
                }
                src_value {
                  value
                }
              }
            }
          }
        }
        footer_background_image {
          value {
            ...KenticoAssetElementFragment
          }
        }
        global_form_stub_banner {
          value {
            ...FormStubBannerFragment
          }
        }
        global_form {
          value {
            ...FormFragment
          }
        }
        footer_additional_information {
          ...RichTextContentFragment
        }

        offices {
          modular_content {
            id
            ...OfficeFragment
          }
        }
        
        image_banner_color_overlay {
          value {
            codename
          }
        }
        text_banner_background_color {
          value {
            codename
          }
        }
        nav_tile_color_overlay {
          value {
            codename
          }
        }
        side_nav_background_color {
          value {
            codename
          }
        }
        primary_color {
          value
        }
        primary_alt_color {
          value
        }
        secondary_color {
          value
        }
        secondary_alt_color {
          value
        }
        accent_color {
          value
        }
        accent_alt_color {
          value
        }
        link_color {
          value
        }

        buy_online_get_started_button {
          value {
            ...AnchorLinkFragment
            ...ButtonFragment
          }
        }
        static_review_items {
          modular_content {
            ...ReviewFragment
            ...ReviewBlockFragment
          }
        }
        fieldroutes_portal {
          value {
            codename
          }
        }
        custom_link_text_fieldroutes_portal {
          value
        }
        fieldroutes_reviews {
          value {
            codename
          }
        }
        custom_eyebrow_text_fieldroutes_reviews{
          value
        }
        custom_link_text_fieldroutes_reviews {
          value
        }
        fieldroutes_review_images {
          modular_content {
            id
            ...SingleImageFragment
          }
        }
        fieldroutes_review_background_image {
          value {
            ...KenticoAssetElementFragment
          }
        }
        ...GoogleReviewsFragment

        header_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        secondary_header_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        side_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        footer_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        credits_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        header_logo {
          value {
            ...KenticoAssetElementFragment
          }
        }
        footer_logo {
          value {
            ...KenticoAssetElementFragment
          }
        }
        side_nav_logo {
          value {
            ...KenticoAssetElementFragment
          }
        }
        favicon {
          value {
            ...KenticoAssetElementFragment
          }
        }




      }
    }
  }
  
`
export const reviewBlockFragment = graphql`
  fragment ReviewBlockFragment on kontent_item_review_block {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      social_logos {
        modular_content {
          ...SingleImageFragment
          ...ImageLinkFragment
        }
      }
      reviews {
        modular_content {
          ...ReviewFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`


export default ghostHome
